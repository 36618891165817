$color: #2c3e50;

.search {
  display: table;
  input {
    background: none;
    border: none;
    outline: none;
    width: 28px;
    min-width: 0;
    padding: 0;
    z-index: 1;
    position: relative;
    line-height: 18px;
    margin: 5px 0;
    font-size: 14px;
    // -webkit-appearance: none;
    // font-family: 'Mukta Malar';
    transition: all 0.6s ease;
    cursor: pointer;
    color: $color;
    & + div {
      position: relative;
      height: 28px;
      width: 100%;
      margin: -28px 0 0 0;
      svg {
        display: block;
        position: absolute;
        height: 28px;
        width: 160px;
        right: 0;
        top: 0;
        fill: none;
        stroke: $color;
        stroke-width: 1.5px;
        stroke-dashoffset: 212.908 + 59;
        stroke-dasharray: 59 212.908;
        transition: all 0.6s ease;
      }
    }
    &:not(:placeholder-shown),
    &:focus {
      width: 160px;
      padding: 0 4px;
      cursor: text;
      & + div {
        svg {
          stroke-dasharray: 150 212.908;
          stroke-dashoffset: 300;
        }
      }
    }
  }
}

::selection {
  background: rgba(#fff, 0.2);
}
::-moz-selection {
  background: rgba(#fff, 0.2);
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: inherit;
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

.search-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  margin-left: 10px;
  .dribbble {
    position: fixed;
    display: block;
    right: 24px;
    bottom: 24px;
    img {
      display: block;
      width: 76px;
    }
  }
}
.search input::placeholder {
  opacity: 0;
}

.search input:focus::placeholder {
  opacity: 1;
}

.focused {
  border-bottom: 2px solid blue;
}

.focused::placeholder {
  color: blue;
}
