table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
  font-size: small;
}

th,
td {
  text-align: left;
  padding: 8px;
  white-space: nowrap;
}
th {
  background-color: #2c3e50;
  color: #ddd;
}

.table-content-wrapper {
  overflow-x: auto;
  max-height: calc(80vh - 160px);
}
.table-wrapper {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin: 1em;
  border-radius: 10px;
  /* max-height: calc(100vh - 120px);
  overflow-y: scroll; */
}
.table-container {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}
.table-header-txt {
  font-weight: 700;
  font-size: large;
}
.table-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
tr:nth-child(odd) {
  background-color: #f2f2f2;
}
.hide {
  display: none;
}

th:last-child {
  text-align: center;
}
.date-filter {
  background-color: #fff;
  /* width: %; */
  /* width: 100%; */
  /* transition: transform 0.3s ease; */
  z-index: 1;
}
.toggle-date-filter {
  color: #fff;
  /* text-align: center; */
  /* justify-content: center; */
  /* display: flex; */
}
.date-filter-input {
  border-bottom: 1.5px solid #2c3e50;
  margin-bottom: 2px;
  /* outline: none; */
}
*:focus {
  outline: none;
}
.cal-icon {
  display: inline-block;
  cursor: pointer;
}

.input-item:before {
  content: "\f573";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.inputField {
  width: 200px;
  height: 30px;
  margin-top: 10px;
  transition: transform 0.3s ease;
}

.swipe-animation {
  transform: translateX(200px);
}

.left-side {
  display: flex;
}
.left-side > button {
  z-index: 999;
  background-color: #2c3e50;
  color: #ddd;
  padding: 0 0.6rem;
  border-radius: 0.2rem;
  margin-right: 2rem;
}
