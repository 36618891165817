.buttons {
  background-color: #00a2ff !important;
  color: black !important;
  font-weight: 700 !important;
  text-transform: capitalize !important;
  font-family: "DM Sans" !important;
}
.create-button {
  color: white !important;
  font-weight: 700 !important;
  text-transform: capitalize !important;
  font-family: "DM Sans" !important;
  padding: 10px 47px;
  border-radius: 15px;
  width: 45%;
  background-color: #00a2ff;
}
.cancel-buttons {
  background: #dcdcdc !important;
  color: white !important;
  font-weight: 700 !important;
  text-transform: capitalize !important;
  font-family: "DM Sans" !important;
  padding: 10px 47px;
  border-radius: 15px;
  width: 45%;
}
.model_btn_wrap {
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paper {
  background-color: white;
  padding: 2px;
  width: 38.8%;
  z-index: 1;
  padding: 10px 10px 10px 10px;
  border-radius: 12px;
  @media screen and (max-width: 1024px) {
    width: 55%;
  }
  @media screen and (max-width: 991px) {
    width: 65%;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
  }
  h2 {
    text-align: center;
    font-size: 20px;
    line-height: 25px;
    font-family: "DM Sans";
    font-weight: 500;
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 95%;
    margin: 0 auto;
    @media screen and (max-width: 991px) {
      max-width: 70%;
    }
    @media screen and (max-width: 767px) {
      max-width: 80%;
    }
    .input-style {
      height: 53px;
      border-radius: 11px;
    }
  }
}
