.btn-common {
  width: 150px;
  background-color: #00a2ff;
  border: none;
  outline: none;
  height: 35px;
  border-radius: 5px;
  color: #fff;

  font-weight: 400;
  margin: 8px 1em;
  cursor: pointer;
  transition: 0.5s;
}
